
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Rate from "@/components/migration/Rate.vue";

export default defineComponent({
  name: "broker-create-migration-rate",
  components: {
    Rate,
  },
  // data() {
  //   return {
  //     redirect: "admin-administrator-users",
  //   };
  // },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Rate", ["Policy Migration"]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Broker: Create Migration Rate | " + process.env.VUE_APP_TITLE;
  },
});
