
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "exisiting-policies-rates-component",
  components: { Field },
  data() {
    return {
      rates: [],
    };
  },
  created() {
    this.getRates();
  },
  methods: {
    getRates() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.RATES_ROUTE}`)
          .then(({ data }) => {
            //Assign data to form fields
            this.rates = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formSubmit(publicId, rateFieldId, submitButtonId) {
      const submitButton1 = document.getElementById(submitButtonId);

      const rateValue = document.getElementById(
        rateFieldId
      ) as HTMLInputElement;

      const payload = {
        value: rateValue.value,
      } as object;

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${variables.RATES_ROUTE}/${publicId}`, payload, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                submitButton1.removeAttribute("data-kt-indicator");
                variables.toastAlert(
                  "Rate was successfully updated.",
                  "success"
                );
                this.getRates();
              })
              .catch(function(error) {
                submitButton1.removeAttribute("data-kt-indicator");
                // console.info(error);
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    },
  },
});
